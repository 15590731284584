/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  CustomPopover,
  IconNavigation,
  showToast,
  Skeleton,
  Spinner,
  TextInput,
} from "../../../../components/shared";
import { CustomModal } from "../../../../components/shared";
import {
  DeleteIcon,
  DotsIcon,
  EditIcon,
  EyeIcon,
  LongArrow,
} from "../../../../assets/icons";
import { AddMore } from "../../../../components/shared/AddMore";
import {
  deleteRecommendationsFunction,
  getRecommendationsFunction,
  updateRecommendationsFunction,
} from "../../../../redux/reducers/userReducers/recommendationsReducer/recommendationsReducer";
import { capitalize } from "../../../../utils/utils";
import { isEmpty, isError, validateEmail } from "../../../../utils/validations";
import { ProfileDeleteModal } from "../DeleteModal";
export const Recommendations = ({ formErrors }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.recommendations);
  const [openRecommendationsModal, setOpenRecommendationsModal] =
    useState(false);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null,
  });
  const [recommendationsList, setRecommendationsList] = useState([]);
  const [error, setError] = useState(null);

  const [newRecommendation, setNewRecommendation] = useState({
    referee: "",
    relation: "",
    phone: "",
    email: "",
    is_verified: true,
  });

  // Close the modal
  const handleCloseModal = () => {
    setOpenRecommendationsModal(false);
    setNewRecommendation({
      referee: "",
      relation: "",
      phone: "",
      email: "",
      is_verified: true,
    });
  };

  // Fetch Recommendations from the API
  const getRecommendations = async () => {
    const data = await dispatch(getRecommendationsFunction());
    setRecommendationsList(data?.payload);
  };

  // Delete a Recommendation
  const deleteRecommendation = async (id) => {
    const data = await dispatch(
      deleteRecommendationsFunction({
        id: id,
      })
    );
    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenRecommendationsModal(false);
      showToast("Recommendation Deleted Successfully", "success", {
        position: "top-center",
      });
      setDeleteModal({ open: false, data: null });
      getRecommendations();
    }
    if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join())?.join("\n"),
        {
          position: "top-center",
        }
      );
    }
  };

  // Add a New Recommendation
  const addRecommendation = async () => {
    let errors = {
      email: "",
    };
    // Validate Email
    if (!validateEmail(newRecommendation?.email)) {
      errors.email = "Invalid Email";
    }
    const id = newRecommendation?.id;
    if (isError(errors)) {
      setError(errors?.email);
    } else {
      const data = await dispatch(
        updateRecommendationsFunction({ newRecommendation, id })
      );

      if (data?.meta?.requestStatus === "fulfilled") {
        setOpenRecommendationsModal(false);
        showToast(
          newRecommendation?.id
            ? "Recommendation updated Successfully"
            : "Recommendation Added Successfully",
          "success",
          {
            position: "top-center",
          }
        );
        setNewRecommendation({
          referee: "",
          relation: "",
          phone: "",
          email: "",
          is_verified: true,
        });
        getRecommendations();
      }
      if (data?.error) {
        showToast(
          data?.payload
            ?.map((error) => Object.values(error).join())
            ?.join("\n"),
          {
            position: "top-center",
          }
        );
      }
    }
  };

  useLayoutEffect(() => {
    getRecommendations();
  }, []);

  // Handle form field changes
  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    // Check for only 20 characters in phone number
    if (name === "phone" && value.length > 14) return;

    if (name === "email") {
      setError(null);
    }

    setNewRecommendation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [openPopover, setOpenPopover] = useState({});
  const handlePopoverToggle = (index) => {
    setOpenPopover((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  return (
    <div>
      <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
        <div className="vertical-center gap-3">
          <LongArrow
            className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
            onClick={() => navigate(-1)}
          />
          <h3 className="text-xl">Recommendations</h3>
        </div>
        <div
          onClick={() => setOpenRecommendationsModal(true)}
          className="flex justify-center items-center gap-3 cursor-pointer"
        >
          <AddMore size={"size-7"} />
          <h3 className="text-xl">Add Recommendations</h3>
        </div>
      </div>

      <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
        <div className="flex flex-col border-b border-grey/30 last:border-0 py-2">
          {loading ? (
            <>
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className="border-b border-grey/30 last:border-0 py-5"
                >
                  <Skeleton key={index} className="w-32 h-4 rounded-full" />

                  <div className="vertical-center gap-2 mt-2">
                    <Skeleton key={index} className="w-20 h-3 rounded-full" />
                    <Skeleton key={index} className="w-20 h-3 rounded-full" />
                  </div>
                  <Skeleton
                    key={index}
                    className="w-28 h-3 rounded-full mt-2"
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              {recommendationsList?.length ? (
                <>
                  {recommendationsList?.map((newRecommendation, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 py-4"
                      >
                        <div className="font-nunito">
                          <div className="vertical-center justify-between">
                            <h2 className="text-lg">
                              {capitalize(newRecommendation?.referee)}
                            </h2>

                            <CustomPopover
                              open={openPopover[index]}
                              setOpen={() => handlePopoverToggle(index)}
                              placement="bottom-end"
                              trigger={
                                <div className="flex items-center gap-2">
                                  <DotsIcon />
                                </div>
                              }
                            >
                              <div className="gap-3 w-32 flex flex-col items-center bg-white p-2 rounded-md">
                                <IconNavigation
                                  icon={<EyeIcon width={22} height={22} />}
                                  text="View"
                                  onClick={() => {
                                    handlePopoverToggle(index);
                                    setOpenRecommendationsModal(true);
                                    setNewRecommendation({
                                      view: true,
                                      referee: newRecommendation?.referee,
                                      relation: newRecommendation?.relation,
                                      phone: newRecommendation?.phone,
                                      email: newRecommendation?.email,
                                    });
                                  }}
                                  row
                                />

                                <IconNavigation
                                  icon={<EditIcon width={22} height={22} />}
                                  text="Edit"
                                  onClick={() => {
                                    handlePopoverToggle(index);
                                    setOpenRecommendationsModal(true);
                                    setNewRecommendation({
                                      edit: true,
                                      id: newRecommendation?.id,
                                      referee: newRecommendation?.referee,
                                      relation: newRecommendation?.relation,
                                      phone: newRecommendation?.phone,
                                      email: newRecommendation?.email,
                                    });
                                  }}
                                  row
                                />
                                <IconNavigation
                                  icon={<DeleteIcon width={22} height={22} />}
                                  text="Delete"
                                  onClick={() =>
                                    setDeleteModal({
                                      open: true,
                                      data: {
                                        id: newRecommendation?.id,
                                        name: "Recommendation",
                                        onClick: deleteRecommendation,
                                      },
                                    })
                                  }
                                  row
                                />
                              </div>
                            </CustomPopover>
                          </div>
                          <div className="vertical-center gap-2 text-sm text-grey">
                            <p>{newRecommendation?.relation}</p>
                            {"|"}
                            <p>{newRecommendation?.phone}</p>
                          </div>
                          <p className="text-sm text-grey">
                            {newRecommendation?.email}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p
                  className="
                -+
                 mu-center font-semibold my-1"
                >
                  No Recommendations Added
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <CustomModal
        title={
          newRecommendation?.view
            ? "Recommendation Detail"
            : newRecommendation?.edit
            ? "Edit Recommendation"
            : "Add Recommendation"
        }
        open={openRecommendationsModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form
          className="flex flex-col gap-5 mb-2"
          onSubmit={(e) => {
            e.preventDefault();
            addRecommendation();
          }}
        >
          {newRecommendation?.view ? (
            ""
          ) : (
            <>
              <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
                <p className="font-bold text-sm text-primary">Your Referrals</p>
              </div>

              <div className="vertical-center gap-3">
                <p className="font-nunito text-lg font-bold">Name of Referee</p>
                {loading ? <Spinner /> : null}
              </div>
            </>
          )}

          <TextInput
            label="Referee Name"
            type="text"
            name="referee"
            value={newRecommendation.referee}
            onChange={handleFieldChange}
            error={formErrors?.referee}
            errorMsg={formErrors?.referee}
            required={!newRecommendation?.view}
            disabled={newRecommendation?.view}
          />

          <TextInput
            label="Relation"
            type="text"
            name="relation"
            value={newRecommendation.relation}
            onChange={handleFieldChange}
            error={formErrors?.relation}
            errorMsg={formErrors?.relation}
            required={!newRecommendation?.view}
            disabled={newRecommendation?.view}
          />

          <TextInput
            label="Phone Number"
            type="number"
            name="phone"
            value={newRecommendation.phone}
            onChange={handleFieldChange}
            error={formErrors?.phone}
            errorMsg={formErrors?.phone}
            required={!newRecommendation?.view}
            disabled={newRecommendation?.view}
          />

          <TextInput
            label="Email"
            type="email"
            name="email"
            value={newRecommendation.email}
            onChange={handleFieldChange}
            error={formErrors?.email || error}
            errorMsg={formErrors?.email || error}
            required={!newRecommendation?.view}
            disabled={newRecommendation?.view}
          />

          {newRecommendation?.view ? (
            <>
              {newRecommendation?.proofUpload ? (
                <p
                  onClick={() =>
                    newRecommendation?.proofUpload &&
                    window.open(newRecommendation?.proofUpload, "_blank")
                  }
                  className="cursor-pointer text-blue-600 hover:text-blue-800 underline flex items-center gap-2"
                >
                  <span className="pl-1">View file</span>
                </p>
              ) : (
                ""
              )}
            </>
          ) : (
            <AddMore
              text={
                newRecommendation?.edit
                  ? "Update Recommendation"
                  : "Add Recommendation"
              }
              onClick={addRecommendation}
              disabled={
                loading ||
                isEmpty({
                  referee: newRecommendation?.referee,
                  relation: newRecommendation?.relation,
                  phone: newRecommendation?.phone,
                  email: newRecommendation?.email,
                })
              }
            />
          )}
        </form>
      </CustomModal>

      <ProfileDeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        loading={loading}
      />
    </div>
  );
};
