// Library Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Local Imports
import axios from "../../../network/https";
import { endPoints } from "../../../network/constant";

// Initial state for profileCompletion
const initialState = {
  profileCompletion: {},
  loading: false,
  error: null,
};

// Get Projects API Function
export const getProfileCompletionFunction = createAsyncThunk(
  "user/getProfileCompletion",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints?.profileCompletion);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const profileCompletionSlice = createSlice({
  name: "user/getProfileCompletion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Projects Reducers
    builder
      .addCase(getProfileCompletionFunction.pending, (state) => {
        state.loading = true;
        state.profileCompletion = {};
        state.error = null;
      })
      .addCase(getProfileCompletionFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.profileCompletion = action.payload;
        state.error = null;
      })
      .addCase(getProfileCompletionFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.profileCompletion = {};
      });
  },
});

export default profileCompletionSlice.reducer;
