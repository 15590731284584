import React from "react";

// Local Imports
import { Skeleton } from "../../../../../components/shared";
import { formatDate } from "../../../../../utils/utils";

export const Education = ({ loading, profileData }) => {
  const { education } = profileData;
  return (
    <>
      <div>
        {loading ? (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} className="w-full h-24 rounded-2xl" />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 mt-6 select-none">
            {education?.map((education, index) => {
              // Major and Minor
              const majorMinor = [];
              majorMinor.push(education?.major);
              majorMinor.push(education?.minor);

              // Duration
              const duration = [];
              duration.push(formatDate(education?.start_date));
              duration.push(formatDate(education?.end_date));

              return (
                <div
                  key={index}
                  className="bg-lightgrey border border-grey/30 rounded-2xl px-4 py-3 w-full min-h-12   select-none"
                >
                  <div className="vertical-center justify-between">
                    <p className="text-md font-semibold truncate">
                      {education?.institution}
                    </p>

                    <p
                      className={`text-[10px] ${
                        education?.verification === "PENDING"
                          ? "bg-gold/30"
                          : education?.verification === "VERIFIED"
                          ? "bg-success"
                          : "bg-danger text-white"
                      } rounded-full w-fit px-3 py-1`}
                    >
                      {education?.verification}
                    </p>
                  </div>
                  <div className="vertical-center text-sm text-grey">
                    <p>{majorMinor?.filter(Boolean).join(", ")}</p>
                  </div>

                  <div className="vertical-center gap-2 text-sm text-grey">
                    <p>{duration?.filter(Boolean).join(" - ")}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
