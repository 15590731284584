import React from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '../../../assets/icons';

export const StatsCard = ({
    title,
    total,
    rate,
    levelUp,
    levelDown,
    children,
}) => {
    return (
        <div className="rounded-md border bg-white py-6 px-7 shadow">
            <div className="flex h-11 w-11 items-center justify-center rounded-full bg-meta-2">
                {children}
            </div>

            <div className="mt-4 flex items-end justify-between">
                <div className='w-2/3'>
                    <h4 className={`text-lg font-bold text-black ${total.toString().length <= 2 && 'mx-3'}`}>
                        {total}
                    </h4>
                    <span className="text-sm font-medium">{title}</span>
                </div>

                <span
                    className={`flex items-center justify-end gap-1 lg:w-1/3 text-sm font-medium ${levelUp && 'text-meta-3'
                        } ${levelDown && 'text-meta-5'} `}
                >
                    {rate}

                    {levelUp && (
                        <ArrowUpIcon className='text-success' />
                    )}
                    {levelDown && (
                        <ArrowDownIcon className='text-danger' />
                    )}
                </span>
            </div>
        </div>
    );
};

