import React, { useRef, useEffect, useState } from "react";
import { Button, Avatar } from "@nextui-org/react";
import { Spinner } from "../Spinner";

export const ImagePicker = ({ image, imageUrl, onImageChange, loading }) => {
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);

  useEffect(() => {
    setError(""); // Clear error when image changes
  }, [image]);

  const validateFile = (file) => {
    if (file.size > 1_000_000) {
      return "File size should be less than 1MB";
    }
    if (
      !["image/jpeg", "image/png", "image/tiff", "image/webp"].includes(
        file.type
      )
    ) {
      return "Invalid file type. Only JPEG, PNG, TIFF, or WebP are allowed.";
    }
    return "";
  };

  const handleFileChange = (event) => {
    event.stopPropagation();
    const file = event.target.files[0];

    if (!file) return;

    // Reset file input to allow re-selection of the same image
    event.target.value = "";

    const errorMessage = validateFile(file);
    if (errorMessage) {
      setError(errorMessage);
      setTimeout(() => setError(""), 2000);
    } else {
      onImageChange?.(file);
    }
  };

  const handleRemoveImage = () => {
    onImageChange?.(null);
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  return (
    <div className="flex items-center gap-4">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/jpeg,image/png,image/tiff,image/webp"
        className="hidden"
        onChange={handleFileChange}
      />

      <div
        onClick={image ? handleRemoveImage : undefined}
        className="cursor-pointer"
      >
        {loading ? (
          <div className="flex justify-center items-center bg-mediumgrey/30 w-[80px] h-[80px] rounded-full">
            <Spinner />
          </div>
        ) : (
          <Avatar
            src={image ? URL.createObjectURL(image) : imageUrl || ""}
            classNames={{
              base: "bg-mediumgrey/30 w-[80px] h-[80px]",
              icon: "text-black/60",
            }}
          />
        )}
      </div>

      <div className="flex flex-col">
        <div className="w-full mt-4">
          <Button
            auto
            onClick={() => fileInputRef.current?.click()}
            variant="flat"
            color={image ? "error" : "primary"}
            className="w-[150px] bg-primary text-white hover:bg-secondary rounded-full transition-colors p-5"
          >
            {image || imageUrl ? "Change Image" : "Upload"}
          </Button>
        </div>

        {/* Error Message with Fixed Height to Prevent Layout Shift */}
        <div className="min-h-[20px] mt-1">
          {error && <p className="text-red-500 text-sm text-center">{error}</p>}
        </div>
      </div>
    </div>
  );
};
