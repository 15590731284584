import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Local Imports
import {
  AuthLayout,
  Button,
  showToast,
  TextInput,
} from "../../../components/shared";
import { AppleIcon, FacebookIcon, GoogleIcon } from "../../../assets/icons";
import { assets } from "../../../assets/images";
import { isEmpty, isError, validateEmail } from "../../../utils/validations";
import { loginFunction } from "../../../redux/reducers/authReducers/authReducers";
import { getProfileDetailFunction } from "../../../redux/reducers/userReducers/editProfileReducers/editProfileReducer";

// Login Form Initial Data
const initialLoginFormData = {
  email: "",
  password: "",
};

// Login Form Errors Initial Data
const initialLoginFormErrors = {
  email: "",
  password: "",
};

const Login = () => {
  const [loginFormData, setLoginFormData] = useState(initialLoginFormData);
  const [loginFormErrors, setLoginFormErrors] = useState(
    initialLoginFormErrors
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.user);

  // Login Form Data on Change
  const handleFormDataChange = (event) => {
    setLoginFormErrors((prev) => ({
      ...prev,
      [event.target.name]: "",
    }));
    setLoginFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const getProfileDetail = async () => {
    await dispatch(getProfileDetailFunction());
  };

  // Login Form Submit
  const handleLogin = async (e) => {
    e.preventDefault();
    if (loginFormData?.email && loginFormData?.password) {
      if (!validateEmail(loginFormData?.email)) {
        setLoginFormErrors((prev) => ({
          ...prev,
          email: "Invalid Email",
        }));
      }

      if (validateEmail(loginFormData?.email)) {
        const data = await dispatch(
          loginFunction({
            email: loginFormData?.email,
            password: loginFormData?.password,
          })
        );

        if (data?.meta?.requestStatus === "rejected") {
          showToast(
            `${data?.payload ? data?.payload : "Something went wrong"}`,
            "error",
            {
              position: "top-center",
            }
          );
          return;
        } else {
          getProfileDetail();
        }
      }
    }
  };

  return (
    <AuthLayout>
      <div className="w-full h-full center">
        <div className="bg-white py-5 sm:rounded-lg w-full sm:w-[30rem] sm:h-fit h-full pt-12 sm:pt-5 shadow-lg">
          <div className="sm:max-h-[80vh] overflow-auto px-5">
            {/* Logo */}
            <div className="font-nunito flex items-baseline justify-center mb-4">
              <img src={assets.logo} alt="logo" className="size-12" />
              <p className="text-gold mt-3 text-2xl font-bold tracking-wide w-fit">
                ealNest.
              </p>
            </div>

            {/* Login Headings */}
            <div className="font-nunito mb-5">
              <p className="text-2xl sm:text-3xl font-bold">Log in</p>
              <p className="text-darkgrey text-sm leading-4 mt-3">
                Enter the details below to log in. It only takes a few seconds
                to complete.
              </p>
            </div>

            {/* Login Form */}
            <form className="flex flex-col gap-5 mb-2" onSubmit={handleLogin}>
              <TextInput
                label="Email Address"
                type="email"
                name="email"
                error={loginFormErrors?.email !== ""}
                errorMsg={loginFormErrors?.email}
                value={loginFormData?.email}
                onChange={handleFormDataChange}
              />
              <TextInput
                label="Password"
                type="password"
                name="password"
                error={loginFormErrors?.password !== ""}
                errorMsg={loginFormErrors?.password}
                value={loginFormData?.password}
                onChange={handleFormDataChange}
              />

              <Button
                variant={"filled"}
                color={"primary"}
                loading={loading}
                disabled={isEmpty(loginFormData) || isError(loginFormErrors)}
                onClick={
                  isEmpty(loginFormData) || isError(loginFormErrors)
                    ? () => {}
                    : handleLogin
                }
              >
                Login
              </Button>
            </form>

            {/* Forgot Password & Sign Up Links */}
            <div className="vertical-center justify-between gap-1 pt-2">
              <div className="vertical-center gap-1">
                <p className="text-darkgrey text-sm">Don't have an account?</p>
                <p
                  className="text-primary text-sm hover:underline cursor-pointer"
                  onClick={() => navigate("/signup")}
                >
                  Sign up
                </p>
              </div>

              <div className="vertical-center">
                <p
                  className="text-darkgrey text-sm cursor-pointer hover:text-primary"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password?
                </p>
              </div>
            </div>

            {/* <Divider text={'OR'} /> */}

            {/* Login Social Options */}
            <div className="hidden items-center justify-center gap-5 font-nunito mt-4">
              <button className="bg-black text-white social-button">
                <AppleIcon width={28} height={28} />
              </button>
              <button className="bg-white border text-black social-button">
                <GoogleIcon width={22} height={22} />
              </button>
              <button className="bg-primary text-white social-button">
                <FacebookIcon width={22} height={22} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
