import React, { useState } from "react";

// Local Imports
import {
  EditProfileIcon,
  SecurityIcon,
  SettingIcon,
} from "../../../assets/icons";
import {
  CustomModal,
  ProfileCard,
  showToast,
  TextInput,
} from "../../../components/shared";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isError, validatePassword } from "../../../utils/validations";
import { resetPasswordFunction } from "../../../redux/reducers/authReducers/authReducers";
import { EditProfile } from "./EditProfile";

const AccountSettings = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const [openSecurityModal, setOpenSecurityModal] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  // ProfileDetails Initial Data
  const initialProfileData = {
    firstName: "",
    lastName: "",
    address: "",
    physicalAddress: "",
    profession: "",
    gender: "",
    birthday: "",
    phoneNumber: "",
    email: "",
    interests: [],
    roles: [],
    currentState: [],
    currentCity: [],
    currentZipCode: "",
    operatedState: [],
    operatedCity: [],
    operatedZipCode: "",
    description: "",
    photo: {},
  };

  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [profileData, setProfileData] = useState(initialProfileData);
  const { loading: profileDataLoading, profileData: profileDetails } =
    useSelector((state) => state.profileDetails);

  // Form Data
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // Errors State
  const [formErrors, setFormErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // Handle Password Change
  const handlePasswordChange = (event) => {
    const { name, value } = event.target;

    if (name === "newPassword") {
      setPasswordCriteria({
        minLength: value.length >= 8,
        hasUppercase: /[A-Z]/.test(value),
        hasLowercase: /[a-z]/.test(value),
        hasNumber: /[0-9]/.test(value),
        hasSpecialChar: /[!@#$%^&*()_\-+=<>?{}[\]~`|\\:;"',./]/.test(value),
      });
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  // Handle Close Modal
  const handleCloseModal = () => {
    setOpenSecurityModal(false);
    setPasswords({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setFormErrors({});
  };

  // Handle Reset Password
  const handleResetPassowrd = async () => {
    if (!passwords?.currentPassword) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        currentPassword: "Current password is required",
      }));
    }

    if (!validatePassword(passwords?.newPassword)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        newPassword:
          "Invalid Password. Please ensure your password meets the following criteria.",
      }));
    }

    if (!validatePassword(passwords?.confirmPassword)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword:
          "Invalid Password. Please ensure your password meets the following criteria.",
      }));
    }

    if (passwords?.newPassword !== passwords?.confirmPassword) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword:
          "Invalid Password. Please ensure your password meets the following criteria.",
      }));
      return;
    }

    if (isError(formErrors)) {
      return;
    }

    const data = await dispatch(
      resetPasswordFunction({
        current_password: passwords?.currentPassword,
        new_password: passwords?.newPassword,
      })
    );

    if (data?.meta?.requestStatus === "rejected") {
      showToast(
        `${
          data?.payload
            ? data?.payload[0]?.current_password
            : "Something went wrong"
        }`,
        "error",
        { position: "top-center" }
      );
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Password updated successfully", "success", {
        position: "top-center",
      });
      handleCloseModal();
    }
  };

  const checkPasswordCriteria = () => {
    if (
      passwordCriteria?.hasLowercase &&
      passwordCriteria?.hasUppercase &&
      passwordCriteria?.hasNumber &&
      passwordCriteria?.hasSpecialChar &&
      passwordCriteria?.minLength
    ) {
      return true;
    }
    return false;
  };

  const handleOpenModal = () => {
    setProfileData((prev) => ({
      ...prev,
      firstName: profileDetails?.first_name,
      lastName: profileDetails?.last_name,
      physicalAddress: profileDetails?.mailing_address?.physical_address || "",
      profession: profileDetails?.profession || "",
      gender: profileDetails?.gender?.toLowerCase() || "",
      birthday: profileDetails?.birthday || "",
      phoneNumber: profileDetails?.phone_no || "",
      interests: profileDetails?.user_interests || [],
      role: profileDetails?.user_roles || [],
      address: profileDetails?.mailing_address?.address || "",
      currentState: `${profileDetails?.mailing_address?.state?.id}` || "",
      currentCity: `${profileDetails?.mailing_address?.city?.id}` || "",
      currentZipCode: profileDetails?.mailing_address?.zipcode || "",
      operatedState: `${profileDetails?.operated_address?.state?.id}` || "",
      operatedCity: `${profileDetails?.operated_address?.city?.id}` || "",
      operatedZipCode: profileDetails?.operated_address?.zipcode || "",
      description: profileDetails?.user_description || "",
    }));
    setOpenProfileModal(true);
  };
  return (
    <>
      <EditProfile
        loading={profileDataLoading}
        profileData={profileData}
        setProfileData={setProfileData}
        openProfileModal={openProfileModal}
        setOpenProfileModal={setOpenProfileModal}
        profileDetails={profileDetails}
      />
      <div>
        <div>
          <div className="font-nunito mt-4 vertical-center gap-4 bg-white p-2 pl-5 rounded-xl">
            <SettingIcon width={40} height={40} />
            <div>
              <h3 className="text-xl">Account Settings</h3>
              {/* <p className="text-darkgrey text-sm">
                Update Your Profile Information
              </p> */}
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 py-5">
            <ProfileCard
              icon={<EditProfileIcon color="#1E009F" />}
              iconSX={"bg-soft-purple"}
              trackSX={"bg-soft-purple"}
              indecatorSX={"bg-primary"}
              title={"Edit Personal Details"}
              text={"Update personal and professional details"}
              range={40}
              onClick={handleOpenModal}
            />

            <ProfileCard
              icon={<SecurityIcon color="#D89B00" />}
              iconSX={"bg-lightgold/10"}
              trackSX={"bg-lightgold/10"}
              indecatorSX={"bg-lightgold"}
              title={" Edit Password"}
              text={"Manage your security settings"}
              range={50}
              onClick={() => setOpenSecurityModal(true)}
            />
          </div>
        </div>

        {/* Reset Password Modal */}
        <CustomModal
          title={"Edit Password"}
          open={openSecurityModal}
          close={handleCloseModal}
          primaryButtonClick={handleResetPassowrd}
          secondaryButtonClick={handleCloseModal}
          primaryButtonText={"Update"}
          primaryButtonLoading={loading}
          primaryButtonDisabled={
            loading ? true : isEmpty(passwords) || isError(formErrors)
          }
        >
          <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
            <p className="font-bold text-sm text-primary">Change Password</p>
          </div>

          <form
            className="flex flex-col gap-5 mb-2"
            onSubmit={handleResetPassowrd}
          >
            <TextInput
              label="Current Password"
              type="password"
              name="currentPassword"
              value={passwords?.currentPassword}
              onChange={handlePasswordChange}
              error={formErrors?.currentPassword}
              errorMsg={formErrors?.currentPassword}
            />
            <TextInput
              label="New Password"
              type="password"
              name="newPassword"
              value={passwords.newPassword}
              onChange={handlePasswordChange}
              error={formErrors?.newPassword}
              errorMsg={formErrors?.newPassword}
              description={
                checkPasswordCriteria()
                  ? "Password Strength: Strong"
                  : "Password Strength: Weak"
              }
            />
            <TextInput
              label="Confirm New Password"
              type="password"
              name="confirmPassword"
              value={passwords.confirmPassword}
              onChange={handlePasswordChange}
              error={formErrors?.confirmPassword}
              errorMsg={formErrors?.confirmPassword}
            />
            <ul className="text-xs select-none ml-1.5 grid-cols-1 xs:grid-cols-2">
              <li className="text-darkgrey text-sm vertical-center gap-2">
                <div
                  className={`list-bullet ${
                    passwordCriteria?.minLength ? "bg-success" : "bg-danger"
                  }`}
                />
                Min 8 characters
              </li>
              <li className="text-darkgrey text-sm vertical-center gap-2">
                <div
                  className={`list-bullet ${
                    passwordCriteria?.hasUppercase ? "bg-success" : "bg-danger"
                  }`}
                />
                One uppercase letter
              </li>
              <li className="text-darkgrey text-sm vertical-center gap-2">
                <div
                  className={`list-bullet ${
                    passwordCriteria?.hasLowercase ? "bg-success" : "bg-danger"
                  }`}
                />
                One lowercase letter
              </li>
              <li className="text-darkgrey text-sm vertical-center gap-2">
                <div
                  className={`list-bullet ${
                    passwordCriteria?.hasNumber ? "bg-success" : "bg-danger"
                  }`}
                />
                One number
              </li>
              <li className="text-darkgrey text-sm vertical-center gap-2">
                <div
                  className={`list-bullet ${
                    passwordCriteria?.hasSpecialChar
                      ? "bg-success"
                      : "bg-danger"
                  }`}
                />
                One special character
              </li>
            </ul>
          </form>
        </CustomModal>
      </div>
    </>
  );
};

export default AccountSettings;
