// Library Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Local Imports
import axios from "../../../network/https";
import { endPoints } from "../../../network/constant";

// Initial state for recommendations
const initialState = {
  recommendations: [],
  loading: false,
  error: null,
};

// Get Recommendations API Function
export const getRecommendationsFunction = createAsyncThunk(
  "user/getRecommendations",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.Recommendation);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Update Recommendations API Function
export const updateRecommendationsFunction = createAsyncThunk(
  "user/updateRecommendations",
  async ({ newRecommendation, id }, { rejectWithValue }) => {
    const url = id
      ? `${endPoints?.Recommendation}${id}/`
      : endPoints?.Recommendation;

    const method = id ? "put" : "post";
    try {
      const { data } = await axios[method](url, newRecommendation);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Delete Recommendations API Function
export const deleteRecommendationsFunction = createAsyncThunk(
  "user/deleteRecommendations",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        endPoints.Recommendation + values.id,
        {
          ...values,
        }
      );
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const recommendationsSlice = createSlice({
  name: "user/recommendations",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // Get Recommendations Reducers
    builder
      .addCase(getRecommendationsFunction.pending, (state) => {
        state.loading = true;
        state.recommendations = null;
        state.error = null;
      })
      .addCase(getRecommendationsFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.recommendations = action.payload;
        state.error = null;
      })
      .addCase(getRecommendationsFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.recommendations = null;
      })
      .addCase(updateRecommendationsFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRecommendationsFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateRecommendationsFunction.rejected, (state, action) => {
        state.loading = false;
        state.recommendations = null;
      })
      .addCase(deleteRecommendationsFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRecommendationsFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteRecommendationsFunction.rejected, (state, action) => {
        state.loading = false;
        state.recommendations = null;
      });
  },
});

export default recommendationsSlice.reducer;
