import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

// Chart.js register
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {

    const chartData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Active Users",
                data: [0, 2, 3, 2, 3, 0, 0, 0, 0, 0, 0, 0],
                backgroundColor: "#1E009F",
            },
            {
                label: "Inactive Users",
                data: [1, 0, 1, 3, 1, 0, 4, 0, 0, 0, 0, 0],
                backgroundColor: "#2964E2",
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: true, position: "bottom", },
            title: { display: false, text: "" },
        },
    };

    return (
        <Bar
            data={chartData}
            options={options}
            style={{ width: "100%", height: "500px" }}
        />
    )
};

export default BarChart;
