// Library Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Local Imports
import axios from "../../../network/https";
import { endPoints } from "../../../network/constant";

const initialState = {
  // Partners
  partners: [],
  partnersLoading: false,
  partnersError: null,

  // Partner Details
  partnerDetails: null,
  partnerDetailsLoading: false,
  partnerDetailsError: null,

  // Suggested Partner Details
  sPartnerDetails: [],
  sPartnerDetailsLoading: false,
  sPartnerDetailsError: null,

  // Partner Status
  partnerStatus: null,
  partnerStatusLoading: false,
  partnerStatusError: null,

  // Top Rated Partner
  topRatedList: null,
  topRatedLoading: false,
  topRatedError: null,
};

// Get Signup Suggestions API Function
export const getPartnersListingFunction = createAsyncThunk(
  "user/getPartnersListing",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.partnerListing);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Get Partner Details API Function
export const getPartnerDetailsFunction = createAsyncThunk(
  "user/getPartnerDetails",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.partnerDetails + values.id);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Update Partner Status API Function
export const updatePartnerStatusFunction = createAsyncThunk(
  "user/updatePartnerStatus",
  async (values, { rejectWithValue }) => {
    try {
      // Define the endpoint and payload for the request
      let endpoint = endPoints.partnerStatus + values?.id + "/interest/";
      let payload = {
        interest: values?.status,
      };

      if (values?.status === "ACCEPT" || values?.status === "REJECT") {
        endpoint = endPoints.partnerStatus + values?.id + "/request-response/";
        payload = {
          action: values?.status,
        };
      }

      const { data } = await axios.post(endpoint, payload);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Top Rated Partners API Function
export const topRatedFunction = createAsyncThunk(
  "user/topRatedStatus",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.TopRatedPartners);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Suggested Partner Details API Function (Renamed to avoid conflict)
export const partnerDetailsFunction = createAsyncThunk(
  "user/getSuggestedPartnerDetails",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        endPoints.SuggestedPartnersDetails + values?.id
      );
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const filterPartnersFunction = createAsyncThunk(
  "user/filterPartners",
  async (filters, { rejectWithValue }) => {
    const { profession, city, skills, interests, search } = filters;

    let queryParams = [];
    if (profession) {
      queryParams.push(`profession=${profession}`);
    }
    if (search) {
      queryParams.push(`search=${search}`);
    }
    if (city) {
      queryParams.push(`city=${city}`);
    }

    if (skills) {
      queryParams.push(`skills=${skills}`);
    }

    if (interests && interests.length > 0) {
      queryParams.push(`interests=${interests}`);
    }

    let endPoint = endPoints.partnerFilter;

    if (queryParams.length > 0) {
      endPoint += "?" + queryParams.join("&");
    }

    try {
      const { data } = await axios.get(endPoint);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const partnersSlice = createSlice({
  name: "user/partners",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getPartnersListingFunction.pending, (state) => {
        state.partnersLoading = true;
        state.partners = null;
        state.partnersError = null;
      })
      .addCase(getPartnersListingFunction.fulfilled, (state, action) => {
        state.partnersLoading = false;
        state.partners = action.payload;
        state.partnersError = null;
      })
      .addCase(getPartnersListingFunction.rejected, (state, action) => {
        state.partnersLoading = false;
        state.partners = null;
        state.partnersError = action.payload;
      })

      // Handle getPartnerDetailsFunction
      .addCase(getPartnerDetailsFunction.pending, (state) => {
        state.partnerDetailsLoading = true;
        state.partnerDetails = null;
        state.partnerDetailsError = null;
      })
      .addCase(getPartnerDetailsFunction.fulfilled, (state, action) => {
        state.partnerDetailsLoading = false;
        state.partnerDetails = action.payload;
        state.partnerDetailsError = null;
      })
      .addCase(getPartnerDetailsFunction.rejected, (state, action) => {
        state.partnerDetailsLoading = false;
        state.partnerDetails = null;
        state.partnerDetailsError = action.payload;
      })

      // Handle partnerDetailsFunction (renamed action type)
      .addCase(partnerDetailsFunction.pending, (state) => {
        state.sPartnerDetailsLoading = true;
        state.sPartnerDetails = null;
        state.sPartnerDetailsError = null;
      })
      .addCase(partnerDetailsFunction.fulfilled, (state, action) => {
        state.sPartnerDetailsLoading = false;
        state.sPartnerDetails = action.payload;
        state.sPartnerDetailsError = null;
      })
      .addCase(partnerDetailsFunction.rejected, (state, action) => {
        state.sPartnerDetailsLoading = false;
        state.sPartnerDetailsError = action.payload;
      })

      // Handle updatePartnerStatusFunction
      .addCase(updatePartnerStatusFunction.pending, (state) => {
        state.partnerStatus = null;
        state.partnerStatusLoading = true;
        state.partnerStatusError = null;
      })
      .addCase(updatePartnerStatusFunction.fulfilled, (state, action) => {
        state.partnerStatus = action.payload;
        state.partnerStatusLoading = false;
        state.partnerStatusError = null;
      })
      .addCase(updatePartnerStatusFunction.rejected, (state, action) => {
        state.partnerStatus = null;
        state.partnerStatusLoading = false;
        state.partnerStatusError = action.payload;
      })

      // Handle topRatedFunction
      .addCase(topRatedFunction.pending, (state) => {
        state.topRatedList = null;
        state.topRatedLoading = true;
        state.topRatedError = null;
      })
      .addCase(topRatedFunction.fulfilled, (state, action) => {
        state.topRatedList = action.payload;
        state.topRatedLoading = false;
        state.topRatedError = null;
      })
      .addCase(topRatedFunction.rejected, (state, action) => {
        state.topRatedList = null;
        state.topRatedLoading = false;
        state.topRatedError = action.payload;
      })

      // Handle filterFunction
      .addCase(filterPartnersFunction.pending, (state) => {
        state.partnersLoading = true;
        state.partnersError = null;
      })
      .addCase(filterPartnersFunction.fulfilled, (state, action) => {
        state.partnersLoading = false;
        state.partners = action.payload;
        state.partnersError = null;
      })
      .addCase(filterPartnersFunction.rejected, (state, action) => {
        state.partnersLoading = false;
        state.partnersError = action.payload;
      });
  },
});

export default partnersSlice.reducer;
