import * as React from "react";
export const ArrowDownIcon = ({ width, height, ...props }) => (
    <svg
        style={{
            width: width || '20px',
            height: height || '20px',
        }}
        fill='currentColor'
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.707 17.707l5-5a.999.999 0 10-1.414-1.414L11 14.586V3a1 1 0 10-2 0v11.586l-3.293-3.293a.999.999 0 10-1.414 1.414l5 5a.999.999 0 001.414 0z"
        />
    </svg>
);
