/* eslint-disable react-hooks/exhaustive-deps */
// Library imports
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import { TopRatedCard } from "../../../components/shared/TopRatedCard";
import { PartnerCard, RedirectLink } from "../../../components/shared";
import {
  getPartnersListingFunction,
  topRatedFunction,
} from "../../../redux/reducers/userReducers/partnersReducer/partnersReducer";
import { getProfileCompletionFunction } from "../../../redux/reducers/userReducers/profileCompletionReducer/profileCompletionReducer";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { topRatedList, topRatedLoading, partners, partnersLoading } =
    useSelector((state) => state?.partners);
  const profileCompletion = useSelector((state) => state?.ProfileCompletion);
  const fetchTopRatedList = async () => {
    const data = await dispatch(topRatedFunction());
    console.log("🚀 ~ fetchTopRatedList ~ data:", data);
  };
  useEffect(() => {
    fetchTopRatedList();
    dispatch(getPartnersListingFunction());
    dispatch(getProfileCompletionFunction());
  }, [dispatch]);

  const projects = [
    {
      id: 1,
      title: "Plumbing and wiring for complex Alpha in Atlanta",
      progress: 20,
      timeline: "03 August - 28 August 2024",
      assignedBy: "Charloh",
      teamMembers: ["NP", "NP", "NP"],
    },
    {
      id: 2,
      title: "Electrical installation for building X in New York",
      progress: 40,
      timeline: "01 July - 15 July 2024",
      assignedBy: "Alex",
      teamMembers: ["AB", "XY", "LP"],
    },
    {
      id: 3,
      title: "HVAC system installation in Phoenix",
      progress: 60,
      timeline: "10 June - 25 June 2024",
      assignedBy: "John",
      teamMembers: ["JD", "WP", "QW"],
    },
    {
      id: 4,
      title: "Plumbing upgrades for Mall Z in Dallas",
      progress: 80,
      timeline: "01 May - 20 May 2024",
      assignedBy: "Sarah",
      teamMembers: ["SH", "ES", "KS"],
    },
    {
      id: 5,
      title: "Wiring setup for Office Complex Y in LA",
      progress: 30,
      timeline: "15 April - 30 April 2024",
      assignedBy: "Michael",
      teamMembers: ["MH", "RC", "RT"],
    },
    {
      id: 6,
      title: "Installation of electrical panels in Chicago",
      progress: 50,
      timeline: "05 March - 20 March 2024",
      assignedBy: "Emma",
      teamMembers: ["EM", "CV", "DF"],
    },
  ];

  return (
    <div className="flex h-[calc(100vh-66px)] overflow-hidden flex-row-reverse gap-1 pt-6">
      {/* <div className="pt-6">
       
      </div> */}
      <div className="hidden md:block md:w-[35%] lg:w-[25%] pl-3 rounded-xl pb-3">
        <div className="bg-white rounded-xl h-1/2 overflow-hidden">
          <div className="flex items-center gap-4 py-2 px-4 border-b border-gray-200">
            <h3 className="text-sm font-semibold text-gray-700">
              Suggested Partners
            </h3>
          </div>

          <div className="relative h-[85%] overflow-auto">
            <div
              className={`mx-4 py-2 mb-2 flex flex-col overflow-x-scroll no-scrollbar`}
            >
              <TopRatedCard
                list={topRatedList}
                fetchTopRatedList={fetchTopRatedList}
                loading={topRatedLoading}
              />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl h-[47%] mt-5 overflow-hidden">
          <div className="flex items-center gap-4 py-2 px-4 border-b border-gray-200">
            <h3 className="text-sm font-semibold text-gray-700">My Network</h3>
          </div>

          <div className="relative h-[85%] overflow-auto">
            <div
              className={` mx-4  py-3 mb-2 flex flex-col gap-1 overflow-x-scroll no-scrollbar`}
            >
              {partners?.matched_partners?.length > 0 ? (
                <TopRatedCard
                  list={partners?.matched_partners}
                  fetchTopRatedList={partners?.matched_partners}
                  loading={partnersLoading}
                  matched
                />
              ) : (
                <p className="text-center">Not found</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-[65%] lg:w-[75%] overflow-auto no-scrollbar">
        {/* Video Section */}
        <div>
          <div className="bg-white rounded-xl">
            <div className="p-4">
              <div className="w-full h-[400px] bg-gray-200 rounded-lg">
                <iframe
                  className="w-full h-full rounded-lg"
                  src="https://www.youtube.com/embed/JoKsuFIrpfk"
                  title="Featured Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        {/* Profile Completion Section */}
        <div className="pt-6 w-full">
          <div className="bg-white rounded-xl w-full shadow-xl">
            <div className="p-6">
              {/* Card Header */}
              <div className="flex h-[60px] items-center p-6 bg-purple-600 rounded-t-lg">
                <h1 className="text-xl font-semibold text-white">
                  Complete Profile
                </h1>
              </div>

              <div className="px-6 py-4 flex-1">
                <p className="text-base text-gray-700 mb-4">
                  {profileCompletion?.profileCompletion === 100
                    ? "Great job! Your profile is 100% complete, and you're ready to enjoy a fully personalized experience."
                    : profileCompletion?.profileCompletion >= 50
                    ? "Almost there! Just a few more steps to complete your profile."
                    : "Your journey has just begun! Complete your profile to unlock exclusive benefits."}
                </p>

                {/* Progress Bar */}
                <div className="space-y-4">
                  <div className="flex flex-col">
                    <div className="flex justify-between mb-1">
                      <span className="text-sm font-medium text-gray-700">
                        Profile Completion
                      </span>
                      <span className="text-sm font-medium text-gray-700">
                        {Number(profileCompletion?.profileCompletion) || 0}%
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
                      <div
                        className="h-2.5 rounded-full bg-gradient-to-r from-teal-400 to-teal-600 transition-all duration-500 ease-in-out"
                        style={{
                          width: `${
                            Number(profileCompletion?.profileCompletion) || 0
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Partners */}
        {/* <div className="pt-6">
          <div className="bg-white rounded-xl">
            <div className="flex w-full justify-between items-center gap-4 p-4 border-b border-gray-200">
              <h3 className="text-xl font-semibold text-gray-700">
                Your Partners
              </h3>

              <RedirectLink
                text="See all"
                onClick={() => navigate("/partners")}
              />
            </div>

            <div className="p-4 grid grid-row-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {topRatedList?.slice(0, 6).map((item) => {
                const location = [];
                return (
                  <PartnerCard
                    key={item?.id}
                    src={item?.profile_image}
                    name={
                      (item?.first_name || "") + " " + (item?.last_name || "")
                    }
                    designation={item?.profession}
                    address={location?.filter(Boolean)?.join(", ")}
                    id={item?.id}
                  />
                );
              })}
            </div>
          </div>
        </div> */}
        {/* Projects  */}
        {/* <div className="pt-6 pb-3">
          <div className="bg-white rounded-xl">
            <div className="flex w-full justify-between items-center gap-4 p-4 border-b border-gray-200">
              <h3 className="text-xl font-semibold text-gray-700">Projects</h3>

              <RedirectLink
                text="See all"
                onClick={() => navigate("/projects")}
              />
            </div>
            <div className="py-4 flex gap-3 overflow-x-auto no-scrollbar mx-4">
              {projects.map((project) => (
                <div
                  key={project.id}
                  className="bg-white border border-gray-200 min-w-[400px] max-h-[400px] p-6 rounded-lg shadow-lg flex flex-col justify-between"
                >
                  <h1 className="text-lg font-semibold mb-4 text-gray-800 truncate">
                    {project.title}
                  </h1>

                  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                    <div
                      className="bg-secondary h-2.5 rounded-full"
                      style={{ width: `${project.progress}%` }}
                    ></div>
                  </div>

                  <div className="flex items-center mb-4">
                    {project.teamMembers.map((member, index) => (
                      <div
                        key={index}
                        className="w-8 h-8 rounded-full overflow-hidden bg-gray-400 flex items-center justify-center"
                      >
                        <img
                          src={`https://randomuser.me/api/portraits/men/${index}.jpg`}
                          alt={member}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    ))}
                    {project.teamMembers.length < 4 && (
                      <div className="ml-2 flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full text-gray-600">
                        +{4 - project.teamMembers.length}
                      </div>
                    )}
                  </div>

                  <p className="text-gray-600 text-sm mb-2">Project Timeline</p>
                  <p className="text-base font-semibold mb-4">
                    {project.timeline}
                  </p>

                  <div className="flex items-center text-gray-600 text-sm">
                    <p>Assigned by {project.assignedBy}</p>
                    <div
                      // key={index}
                      className="w-8 h-8 rounded-full overflow-hidden bg-gray-400 flex items-center justify-center"
                    >
                      <img
                        src={`https://randomuser.me/api/portraits/men/${8}.jpg`}
                        alt={"*"}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <div className="pt-6 pb-3">
          <div className="bg-gray-300 rounded-xl">
            {" "}
            <div className="flex w-full justify-between items-center gap-4 p-4 border-b border-gray-400">
              <h3 className="text-xl font-semibold text-gray-600">
                Projects(Coming soon)
              </h3>{" "}
              <RedirectLink
                text="See all"
                onClick={() => navigate("/projects")}
                className="text-gray-500 hover:text-gray-700"
              />
            </div>
            <div className="py-4 flex gap-3 overflow-x-auto no-scrollbar mx-4">
              {projects.map((project) => (
                <div
                  key={project.id}
                  className="bg-gray-400 border border-gray-500 min-w-[400px] max-h-[400px] p-6 rounded-lg shadow-md flex flex-col justify-between opacity-80"
                >
                  <h1 className="text-lg font-semibold mb-4 text-gray-700 truncate">
                    {project.title}
                  </h1>

                  <div className="w-full bg-gray-500 rounded-full h-2.5 mb-4">
                    <div
                      className="bg-dealnest-gold h-2.5 rounded-full"
                      style={{ width: `${project.progress}%` }}
                    ></div>
                  </div>

                  <div className="flex items-center mb-4">
                    {project.teamMembers.map((member, index) => (
                      <div
                        key={index}
                        className="w-8 h-8 rounded-full overflow-hidden bg-gray-500 flex items-center justify-center"
                      >
                        <img
                          src={`https://randomuser.me/api/portraits/men/${index}.jpg`}
                          alt={member}
                          className="w-full h-full object-cover opacity-80"
                        />
                      </div>
                    ))}
                    {project.teamMembers.length < 4 && (
                      <div className="ml-2 flex items-center justify-center w-8 h-8 bg-gray-500 rounded-full text-gray-700">
                        +{4 - project.teamMembers.length}
                      </div>
                    )}
                  </div>

                  <p className="text-gray-700 text-sm mb-2">Project Timeline</p>
                  <p className="text-base font-semibold mb-4 text-gray-800">
                    {project.timeline}
                  </p>

                  <div className="flex items-center text-gray-700 text-sm">
                    <p>Assigned by {project.assignedBy}</p>
                    <div className="w-8 h-8 rounded-full overflow-hidden bg-gray-500 flex items-center justify-center">
                      <img
                        src={`https://randomuser.me/api/portraits/men/${8}.jpg`}
                        alt={"*"}
                        className="w-full h-full object-cover opacity-80"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
