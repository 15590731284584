/* eslint-disable react-hooks/exhaustive-deps */
// Library Imports
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// Local Imports
import { StatsCard } from '../../../components/shared'
import { PartnersIcon, ProjectsIcon, UsersIcon } from '../../../assets/icons'
import BarChart from '../../../components/shared/Charts/BarChart'
import { usersListingFunction } from '../../../redux/reducers/adminReducers/usersReducer'

const Dashboard = () => {
    const dispatch = useDispatch();
    const [dashBoardStats, setDashBoardStats] = useState({
        totalUsers: 0,
        activeUsers: 0,
        inActiveUsers: 0,
        totalProjects: 4
    });

    useEffect(() => {
        const getUserListing = async () => {
            const response = await dispatch(usersListingFunction())
            setDashBoardStats({
                ...dashBoardStats,
                totalUsers: response?.payload?.length,
                activeUsers: response?.payload?.filter(user => user?.is_active === true)?.length,
                inActiveUsers: response?.payload?.filter(user => user?.is_active === false)?.length
            })
        }
        getUserListing();
    }, [dispatch])

    return (
        <div className="p-4 md:p-6 2xl:p-10">

            {/* Stats Card */}
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
                <StatsCard title="All Users" total={dashBoardStats?.totalUsers} rate={"100%"} levelUp>
                    <UsersIcon className='h-16 w-20 !text-primary' />
                </StatsCard>
                <StatsCard title="Active Users" total={dashBoardStats?.activeUsers} rate={(dashBoardStats?.activeUsers / dashBoardStats?.totalUsers) * 100 + '%' || '50%'} levelUp>
                    <PartnersIcon className='h-16 w-20 !text-primary' />
                </StatsCard>
                <StatsCard title="InActive Users" total={dashBoardStats?.inActiveUsers} rate={(dashBoardStats?.inActiveUsers / dashBoardStats?.totalUsers) * 100 + '%' || '50%'} levelDown>
                    <PartnersIcon className='h-16 w-20 !text-secondary' />
                </StatsCard>
                <StatsCard title="Total Projects" total={dashBoardStats?.totalProjects} rate={'10%'} levelDown>
                    <ProjectsIcon className='h-16 w-20 !text-secondary' />
                </StatsCard>
            </div>
            {/* Charts */}
            <div className="my-4 rounded-md border bg-white pt-4 pb-2 px-2 shadow h-full">
                <BarChart />
            </div>
        </div>
    )
}

export default Dashboard
