// Library Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Local Imports
import axios from "../../../network/https";
import { endPoints } from "../../../network/constant";

// Initial state for projects
const initialState = {
  projects: [],
  loading: false,
  error: null,
};

// Get Projects API Function
export const getProjectFunction = createAsyncThunk(
  "user/getProject",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints?.ProjectExperience);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Projects API Function
export const updateProjectFunction = createAsyncThunk(
  "user/udateProject",
  async ({ formData, id }, { rejectWithValue }) => {
    const url = id
      ? `${endPoints?.ProjectExperience}${id}/`
      : endPoints?.ProjectExperience;

    const method = id ? "put" : "post";
    try {
      const { data } = await axios[method](url, formData);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Projects API Function
export const deleteProjectFunction = createAsyncThunk(
  "user/deleteProjects",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        endPoints.ProjectExperience + values.id,
        {
          ...values,
        }
      );
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const projectsSlice = createSlice({
  name: "user/projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Projects Reducers
    builder
      .addCase(getProjectFunction.pending, (state) => {
        state.loading = true;
        state.projects = null;
        state.error = null;
      })
      .addCase(getProjectFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = action.payload;
        state.error = null;
      })
      .addCase(getProjectFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.projects = null;
      })
      .addCase(updateProjectFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProjectFunction.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateProjectFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteProjectFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProjectFunction.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteProjectFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default projectsSlice.reducer;
