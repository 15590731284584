/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  TextInput,
  CustomModal,
  AddMore,
  showToast,
  Spinner,
  Skeleton,
  IconNavigation,
  CustomPopover,
} from "../../../../components/shared";
import {
  DeleteIcon,
  DotsIcon,
  EditIcon,
  EyeIcon,
  LongArrow,
} from "../../../../assets/icons";
import {
  deleteCertificatesFunction,
  getCertificatesFunction,
  updateCertificatesFunction,
} from "../../../../redux/reducers/userReducers/certificationReducer/certificationReducer";
import { isEmpty } from "../../../../utils/validations";
import { formatDate } from "../../../../utils/utils";
import { ProfileDeleteModal } from "../DeleteModal";

export const Certifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { certifications, loading } = useSelector(
    (state) => state?.certifications
  );
  const [openCerificationsModal, setOpenCerificationsModal] = useState(false);
  const [openPopover, setOpenPopover] = useState({});
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null,
  });
  const [certification, setCertification] = useState({
    certificationName: "",
    link: "",
    date: "",
    organization: "",
    proofUpload: null,
    certificationNumber: "",
  });
  const [dateError, setDateError] = useState("");
  // Get Certifications
  const getCertifications = async () => {
    await dispatch(getCertificatesFunction());
  };

  useLayoutEffect(() => {
    getCertifications();
  }, []);

  // Handle Certification Change
  const handleCertificationChange = (event) => {
    const { name, value } = event.target;
    // const today = new Date().toISOString().split("T")[0];
    // const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    // if (name === "date") {
    //   setCertification((prev) => ({
    //     ...prev,
    //     [name]: "",
    //   }));

    //   // Validate format
    //   if (!dateRegex.test(value)) {
    //     setDateError("Invalid date format (YYYY-MM-DD required)");
    //     return;
    //   }

    //   // Validate future date
    //   if (value > today) {
    //     setDateError("Date cannot be in the future");
    //     return;
    //   }
    // }
    setCertification((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   if (dateError) {
  //     const timeout = setTimeout(() => {
  //       setDateError("");
  //     }, 2000);

  //     return () => clearTimeout(timeout);
  //   }
  // }, [dateError]);

  // Add Certifications
  const addCertification = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", certification.certificationName);
    formData.append("certificate_url", certification.link);
    formData.append("date", certification.date);
    formData.append("organization", certification.organization);

    if (certification.proofUpload) {
      formData.append("file_upload", certification.proofUpload);
    }
    if (certification?.certificationNumber) {
      formData.append("cert_num", certification.certificationNumber);
    }
    const id = certification?.id;
    try {
      const data = await dispatch(updateCertificatesFunction({ formData, id }));

      // Check if the request was fulfilled or rejected
      if (data?.meta?.requestStatus === "fulfilled") {
        setOpenCerificationsModal(false);
        showToast(
          certification?.id
            ? "Certificate update Successfully"
            : "Certificate Added Successfully",
          "success",
          {
            position: "top-center",
          }
        );
        getCertifications(); // Fetch updated certifications list
        setCertification({
          certificationName: "",
          link: "",
          date: "",
          organization: "",
          proofUpload: null,
        }); // Reset certification state
      } else if (data?.error) {
        showToast(
          data?.payload?.map((error) => Object.values(error).join()).join("\n"),
          "error",
          { position: "top-center" }
        );
      }
    } catch (error) {
      showToast("An unexpected error occurred", "error", {
        position: "top-center",
      });
    }
  };

  // Delete Certifications
  const handleCloseModal = () => {
    setOpenCerificationsModal(false);
    setCertification({
      certificationName: "",
      link: "",
      date: "",
      organization: "",
      proofUpload: null,
      certificationNumber: "",
    });
  };

  // Delete Certifications
  const deleteCertifications = async (id) => {
    const data = await dispatch(
      deleteCertificatesFunction({
        id: id,
      })
    );

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Certificate Deleted Successfully", "success", {
        position: "top-center",
      });
      setDeleteModal({ open: false, data: null });
      getCertifications();
    }
  };

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCertification((prevState) => ({
      ...prevState,
      proofUpload: file,
    }));
  };

  const handlePopoverToggle = (index) => {
    setOpenPopover((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  return (
    <div>
      <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
        <div className="vertical-center gap-3">
          <LongArrow
            className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
            onClick={() => navigate(-1)}
          />
          <h3 className="text-xl">Certifications</h3>
        </div>
        <div
          onClick={() => setOpenCerificationsModal(true)}
          className="flex justify-center items-center gap-3 cursor-pointer"
        >
          <AddMore size={"size-7"} />
          <h3 className="text-xl">Add Certifications</h3>
        </div>
      </div>

      <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
        <div className="flex flex-col gap-6 mt-4">
          {loading ? (
            <>
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className="border-b border-grey/30 last:border-0 pb-5 flex flex-col gap-2"
                >
                  <Skeleton key={index} className="h-4 w-40 rounded-full" />
                  <Skeleton className="h-3 w-24 rounded-full" />
                  <Skeleton className="h-3 w-32 rounded-full" />
                </div>
              ))}
            </>
          ) : (
            <>
              {certifications?.length > 0 ? (
                <>
                  {certifications?.map((certificate, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 pb-5"
                      >
                        <div className="vertical-center justify-between">
                          <p>{certificate?.name}</p>

                          <CustomPopover
                            open={openPopover[index]}
                            setOpen={() => handlePopoverToggle(index)}
                            placement="bottom-end"
                            trigger={
                              <div className="flex items-center gap-2">
                                <DotsIcon />
                              </div>
                            }
                          >
                            <div className="gap-3 w-32 flex flex-col items-center bg-white p-2 rounded-md">
                              {/* View Icon */}
                              <IconNavigation
                                icon={<EyeIcon width={22} height={22} />}
                                text="View"
                                onClick={() => {
                                  handlePopoverToggle(index);
                                  setOpenCerificationsModal(true);
                                  setCertification({
                                    view: true,
                                    certificationName: certificate?.name,
                                    certificationNumber: certificate?.cert_num,
                                    link: certificate?.certificate_url,
                                    date: certificate?.date,
                                    organization: certificate?.organization,
                                    proofUpload: certificate?.file_upload_url
                                      ? certificate?.file_upload_url
                                      : "",
                                  });
                                }}
                                row
                              />

                              {/* View Icon */}
                              <IconNavigation
                                icon={<EditIcon width={22} height={22} />}
                                text="Edit"
                                onClick={() => {
                                  handlePopoverToggle(index);
                                  setOpenCerificationsModal(true);
                                  setCertification({
                                    edit: true,
                                    id: certificate?.id,
                                    certificationName: certificate?.name,
                                    link: certificate?.certificate_url,
                                    date: certificate?.date,
                                    organization: certificate?.organization,
                                    certificationNumber: certificate?.cert_num,
                                  });
                                }}
                                row
                              />

                              {/* Delete Icon */}
                              <IconNavigation
                                icon={<DeleteIcon width={22} height={22} />}
                                text="Delete"
                                onClick={() => {
                                  handlePopoverToggle(index);
                                  setDeleteModal({
                                    open: true,
                                    data: {
                                      id: certificate?.id,
                                      name: "Certificate",
                                      onClick: deleteCertifications,
                                    },
                                  });
                                }}
                                row
                              />
                            </div>
                          </CustomPopover>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{certificate?.organization}</p>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{formatDate(certificate?.date)}</p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="text-center font-semibold mb-2">
                  No Certifications Found
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <CustomModal
        title={
          certification?.view
            ? "Certification Detail"
            : certification?.id
            ? "Edit certification"
            : "Add Certification"
        }
        open={openCerificationsModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form onSubmit={addCertification} className="space-y-4">
          <div className="flex flex-col gap-5">
            {certification?.view ? (
              ""
            ) : (
              <>
                <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
                  <p className="font-bold text-sm text-primary">
                    Personal Certifications
                  </p>
                </div>

                <div className="vertical-center gap-2">
                  <h2 className="text-lg font-bold text-gray-800">
                    Certifications
                  </h2>
                  {loading ? <Spinner /> : null}
                </div>
              </>
            )}
            <div className={`space-y-4`}>
              <TextInput
                label="Certification Name"
                name="certificationName"
                value={certification?.certificationName}
                onChange={handleCertificationChange}
                required={certification?.view ? false : true}
                disabled={certification?.view ? true : false}
              />
              {certification?.view && !certification?.certificationNumber ? (
                ""
              ) : (
                <TextInput
                  label="Certification Number"
                  name="certificationNumber"
                  type={"number"}
                  value={certification?.certificationNumber}
                  onChange={handleCertificationChange}
                  required={certification?.view ? false : true}
                  disabled={certification?.view ? true : false}
                />
              )}
              {certification?.view ? (
                ""
              ) : (
                <TextInput
                  label="Link"
                  name="link"
                  value={certification.link}
                  onChange={handleCertificationChange}
                />
              )}
              <TextInput
                label="Expiration Date"
                name="date"
                type="date"
                value={certification?.date}
                onChange={handleCertificationChange}
                required={certification?.view ? false : true}
                disabled={certification?.view ? true : false}
                error={dateError}
                errorMsg={dateError}
              />

              <TextInput
                label="Organization"
                name="organization"
                value={certification.organization}
                onChange={handleCertificationChange}
                required={certification?.view ? false : true}
                disabled={certification?.view ? true : false}
              />
              {certification?.view ? (
                ""
              ) : (
                <>
                  <div>
                    <label
                      htmlFor="proofUpload"
                      className="block text-[14px] font-medium text-mediumgray"
                    >
                      Proof of Certification
                    </label>
                    <input
                      type="file"
                      id="proofUpload"
                      name="proofUpload"
                      onChange={handleFileChange}
                      className="mt-1 block w-fit text-sm text-mediumgray"
                    />
                  </div>
                </>
              )}
            </div>
            {certification?.view ? (
              <>
                {certification?.proofUpload ? (
                  <p
                    onClick={() =>
                      certification?.proofUpload &&
                      window.open(certification?.proofUpload, "_blank")
                    }
                    className="cursor-pointer text-blue-600 hover:text-blue-800 underline flex items-center gap-2"
                  >
                    <span className="pl-1">View file</span>
                  </p>
                ) : (
                  ""
                )}
                {certification?.link ? (
                  <p
                    onClick={() =>
                      certification?.link &&
                      window.open(certification?.link, "_blank")
                    }
                    className="cursor-pointer text-blue-600 hover:text-blue-800 underline flex items-center gap-2"
                  >
                    <span className="pl-1">View link</span>
                  </p>
                ) : (
                  ""
                )}
              </>
            ) : (
              <AddMore
                text={
                  certification?.id ? "Edit Certificate" : "Add Certificate"
                }
                onClick={addCertification}
                disabled={
                  loading
                    ? true
                    : isEmpty({
                        name: certification?.certificationName,
                        date: certification?.date,
                        organization: certification?.organization,
                      })
                }
              />
            )}
          </div>
        </form>
      </CustomModal>

      <ProfileDeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        loading={loading}
      />
    </div>
  );
};
