import React, { useState } from "react";
import { Avatar, Skeleton } from "@nextui-org/react";

// Local Imports
import {
  BagIcon,
  ChatIcon,
  CrossIcon,
  FilledStarIcon,
  LocationIcon,
  StarIcon,
  TrueIcon,
} from "../../../assets/icons";
import { capitalize } from "../../../utils/utils";
import { CustomModal } from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnerDetailsFunction,
  getPartnersListingFunction,
  updatePartnerStatusFunction,
} from "../../../redux/reducers/userReducers/partnersReducer/partnersReducer";
import { PartnersProfileButton } from "../PartnersProfileButton";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomTooltip } from "../Tooltip";
import { useSocket } from "../../../redux/socket/socket";

export const PartnerCard = ({
  src,
  name,
  designation,
  address,
  id,
  search,
  handleClearFilter,
}) => {
  const { sendCommand } = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { partnerDetailsLoading, partners: partnersListing } = useSelector(
    (state) => state?.partners
  );
  const [openPartnerModal, setOpenPartnerModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [partnerDetail, setPartnerDetail] = useState(null);

  // Handle Partner Details Modal
  const handleCloseModal = () => {
    setOpenPartnerModal(false);
  };

  // Handle Status Functions
  const handleStatusChange = (status, id) => {
    setStatus(status);
    updatePartnerStatus(status, id);
  };

  // Update Partner Status
  const updatePartnerStatus = async (status, id) => {
    const data = await dispatch(
      updatePartnerStatusFunction({
        id: id,
        status: status?.toUpperCase(),
      })
    );

    if (data?.meta?.requestStatus === "fulfilled") {
      sendCommand("fetch_channels");
      handleCloseModal();
      handleClearFilter?.();
      await dispatch(getPartnersListingFunction());
    }
  };

  // Get Partner Details Function
  const getPartnerDetails = async (id) => {
    const data = await dispatch(
      getPartnerDetailsFunction({
        id: id,
      })
    );
    setPartnerDetail(data?.payload);
  };

  const isPartnerExists = (array, idToCheck) => {
    return array?.some((item) => item?.partner?.id === idToCheck);
  };
  return (
    <>
      <div
        onClick={() => {
          setOpenPartnerModal(true);
          getPartnerDetails(id);
        }}
        className="border relative rounded-3xl bg-white hover:shadow-md transition p-3 vertical-center gap-3 group cursor-pointer select-none"
      >
        <Avatar
          showFallback
          size="xl"
          color="secondary"
          className="!size-16"
          classNames={{
            base: "bg-mediumgrey/30",
            icon: "text-black/60",
          }}
          src={src}
        />
        <div className="font-nunito">
          <p className="text-sm font-semibold">
            {name
              ?.split(" ")
              ?.map((word) => capitalize(word))
              .join(" ") || "--"}
          </p>
          <p className="text-[13px] text-darkgrey">
            {designation
              ?.split(" ")
              ?.map((word) => capitalize(word))
              .join(" ") || "-"}
          </p>
          <p className="text-xs text-grey">
            {address
              ?.split(" ")
              ?.map((word) => capitalize(word))
              .join(" ") || "-"}
          </p>
        </div>
        {!search ? (
          isPartnerExists(partnersListing?.prioritized_partners, id) ? (
            <div
              onClick={(event) => {
                event.stopPropagation();
                handleStatusChange("MATCH", id);
              }}
              className="absolute top-3 right-3 cursor-pointer text-gold"
            >
              <FilledStarIcon />
            </div>
          ) : (
            <div
              onClick={(event) => {
                event.stopPropagation();
                handleStatusChange("prioritize", id);
              }}
              className="absolute top-3 right-3 cursor-pointer"
            >
              <StarIcon />
            </div>
          )
        ) : (
          ""
        )}
      </div>

      {/* Modal  */}
      <CustomModal
        title={
          partnerDetailsLoading ? (
            ""
          ) : location?.pathname === "/suggestions" ? (
            <div className="vertical-center gap-2">
              <Avatar
                src={src ? src : null}
                classNames={{
                  base: "bg-mediumgrey/30",
                  icon: "text-black/60",
                }}
              />
              <div className="font-nunito">
                <p className="font-semibold text-sm">
                  {partnerDetail
                    ? capitalize(partnerDetail?.first_name) +
                      " " +
                      capitalize(partnerDetail?.last_name)
                    : "John Doe"}
                </p>
                <p className="font-semibold text-[13px] text-darkgrey">
                  {partnerDetail ? partnerDetail?.role : "--"}
                </p>
              </div>
            </div>
          ) : (
            <CustomTooltip content={"View profile"}>
              <div
                onClick={() => {
                  navigate(`/partners/partner-detail/${id}`);
                }}
                className="vertical-center gap-2 cursor-pointer"
              >
                <Avatar
                  src={src ? src : null}
                  classNames={{
                    base: "bg-mediumgrey/30",
                    icon: "text-black/60",
                  }}
                />
                <div className="font-nunito">
                  <p className="font-semibold text-sm">
                    {partnerDetail
                      ? capitalize(partnerDetail?.first_name) +
                        " " +
                        capitalize(partnerDetail?.last_name)
                      : "John Doe"}
                  </p>
                  <p className="font-semibold text-[13px] text-darkgrey">
                    {partnerDetail ? partnerDetail?.role : "--"}
                  </p>
                </div>
              </div>
            </CustomTooltip>
          )
        }
        open={openPartnerModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        {partnerDetailsLoading ? (
          <>
            <div className="font-nunito pb-3 flex justify-between items-center">
              <Skeleton className="flex rounded-full w-20 h-3" />
              <Skeleton className="flex rounded-full w-20 h-3" />
            </div>

            <div className="flex flex-col gap-2">
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-20 h-3" />
            </div>

            <div className="center flex-col gap-2">
              <Skeleton className="flex rounded-full w-32 h-6 mb-4" />

              <div className="vertical-center gap-2">
                <Skeleton className="flex rounded-full w-32 h-3" />
                <Skeleton className="flex rounded-full w-32 h-3" />
              </div>
            </div>

            <div className="center gap-2 mt-6">
              <Skeleton className="flex rounded-md size-20" />
              <Skeleton className="flex rounded-md size-20" />
              <Skeleton className="flex rounded-md size-20" />
            </div>
          </>
        ) : (
          <>
            <div className="font-nunito pb-3 flex justify-between items-center">
              {partnerDetail?.profession ? (
                <div className="flex justify-center items-center gap-1">
                  <BagIcon color={"#85838F"} />
                  <p className="font-bold text-base text-darkGray">
                    {partnerDetail?.profession}
                  </p>
                </div>
              ) : null}
              <div className="flex justify-center items-center gap-1 ml-auto bg-gold/10 px-2.5 rounded-[20px]">
                <LocationIcon color={"#D89B00"} />
                <p className="font-bold text-sm text-gold">
                  {partnerDetail?.mailing_address?.city?.name +
                    ", " +
                    partnerDetail?.mailing_address?.state?.name}
                </p>
              </div>
            </div>

            <p className="font-nunito text-sm pb-3 min-h-[64px] text-darkgrey ">
              {partnerDetail?.user_description ? (
                partnerDetail?.user_description
              ) : (
                <span className="text-darkgrey font-nunito text-sm ">
                  No description added yet.
                </span>
              )}
            </p>

            <div className="flex justify-center items-center gap-5">
              {location?.pathname === "/partners/requests" ? (
                <>
                  <PartnersProfileButton
                    icon={<TrueIcon color={"#1E009F"} />}
                    value={"ACCEPT"}
                    selectedValue={status}
                    onChange={(event) =>
                      handleStatusChange(event.target.value, id)
                    }
                    label="ACCEPT"
                  />

                  <PartnersProfileButton
                    icon={<CrossIcon color={"red"} height={24} width={24} />}
                    value={"REJECT"}
                    selectedValue={status}
                    onChange={(event) =>
                      handleStatusChange(event.target.value, id)
                    }
                    label="REJECT"
                  />
                </>
              ) : (
                <>
                  <PartnersProfileButton
                    icon={<CrossIcon color={"red"} height={24} width={24} />}
                    value={"deprioritize"}
                    selectedValue={status}
                    onChange={(event) =>
                      handleStatusChange(event.target.value, id)
                    }
                    label="Skip"
                  />
                  {location?.pathname === "/partners/my-network" ? (
                    <PartnersProfileButton
                      icon={<ChatIcon color={"#1E009F"} />}
                      // value={"match"}
                      selectedValue={status}
                      onChange={(event) =>
                        navigate(`/chat`, {
                          state: {
                            user_id: id,
                            completeItem: {
                              avatar: partnerDetail?.profile_image || "",
                              channel_id: null,
                              chatType: "individual",
                              id: id,
                              name:
                                capitalize(partnerDetail?.first_name) +
                                  " " +
                                  capitalize(partnerDetail?.last_name) || "",
                              online: false,
                              selected: false,
                            },
                          },
                        })
                      }
                      label="Chat"
                    />
                  ) : (
                    <PartnersProfileButton
                      icon={<TrueIcon color={"#1E009F"} />}
                      value={"match"}
                      selectedValue={status}
                      onChange={(event) =>
                        handleStatusChange(event.target.value, id)
                      }
                      label="Match"
                    />
                  )}
                </>
              )}
            </div>
          </>
        )}
      </CustomModal>
    </>
  );
};
