import React, { useState } from "react";
import { AvatarWithDetails } from "../AvatarWithDetails";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CrossIcon, TrueIcon } from "../../../assets/icons";
import { updatePartnerStatusFunction } from "../../../redux/reducers/userReducers/partnersReducer/partnersReducer";
import { CustomTooltip } from "../Tooltip";
import { Skeleton } from "../Skeleton";

export const TopRatedCard = ({
  list,
  fetchTopRatedList,
  loading,
  matched,
  src,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [status, setSelectedStatuses] = useState({});

  const handleStatusChange = (status, id, event) => {
    event.stopPropagation();
    setSelectedStatuses((prev) => ({
      ...prev,
      [id]: prev[id] === status ? null : status,
    }));
    updatePartnerStatus(status, id);
  };

  const updatePartnerStatus = async (status, id) => {
    const data = await dispatch(
      updatePartnerStatusFunction({
        id,
        status: status?.toUpperCase(),
      })
    );

    if (data.meta.requestStatus === "fulfilled") {
      fetchTopRatedList();
    }
  };

  return (
    <div className={`flex flex-col `}>
      {loading ? (
        Array.from({ length: 15 }, (_, index) => (
          <Skeleton key={index} className="h-10 my-2 rounded-md" />
        ))
      ) : (
        <>
          {list?.map((item) => {
            const selectedStatus = status[item.id];
            const partner = matched ? item?.user : item;
            return (
              <div
                key={item.id}
                className="py-[4px] flex ml-0 justify-between items-center rounded-lg bg-white transition duration-300 ease-in-out transform hover:shadow-lg cursor-pointer"
                onClick={() =>
                  navigate(`/partners/partner-detail/${partner.id}`)
                }
              >
                <AvatarWithDetails
                  src={item?.profile_image}
                  name={partner.first_name}
                  designation={item.profession}
                  sx={"!flex !flex-row !justify-center !items-center"}
                  descriptioSx={"pl-2"}
                  avatarSx={"!w-[30px] !h-[30px] "}
                />

                <div className="flex gap-2">
                  {matched ? (
                    ""
                  ) : (
                    <CustomTooltip content={"Match"}>
                      <div
                        onClick={(e) => handleStatusChange("match", item.id, e)}
                        className={`p-0.5 rounded-lg border-[1.5px] ${
                          selectedStatus === "match"
                            ? " border-blue-500"
                            : " border-lightgray"
                        }`}
                      >
                        <TrueIcon
                          color={
                            selectedStatus === "match" ? "#1E009F" : "gray"
                          }
                          width={15}
                          height={15}
                        />
                      </div>
                    </CustomTooltip>
                  )}
                  <CustomTooltip content={"Skip"}>
                    <div
                      onClick={(e) =>
                        handleStatusChange("deprioritize", partner.id, e)
                      }
                      className={`p-0.5 rounded-lg border-[1.5px] ${
                        selectedStatus === "deprioritize"
                          ? " border-red-500"
                          : "border-lightgray"
                      }`}
                    >
                      <CrossIcon
                        color={
                          selectedStatus === "deprioritize" ? "red" : "gray"
                        }
                        width={15}
                        height={15}
                      />
                    </div>
                  </CustomTooltip>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
